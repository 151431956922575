import { useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useAuth } from "../provider/auth_provider";
import Profile from "../components/profile";
import UserAgreement from "../components/UserAgreement";
import LandingFallback from "./landingFallback";
import Support from "../components/support";
import DocumentAnalyzerImage from "./../assets/landing/document-analyzer.svg";
import { clientName } from "../config";
import theme from "../theme";
import CompetetiveAnalysisImage from "./../assets/landing/competitor-analysis.svg";
import productAnalysisImage from "./../assets/landing/product-analysis.svg";
import ctrlNLogo from "./../assets/teamlogo.svg";
import logo from "../assets/HDFC-Ergo-logo.png";

type CardItemProps = {
  image: string;
  title: string;
  description: string;
  link: string;
  buttonText: string;
  show: boolean;
};

const CardItem = ({
  image,
  title,
  description,
  link,
  buttonText,
  show,
}: CardItemProps) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  if (!show) return null;

  return (
    <Grid item lg={4}>
      <Card
        elevation={8}
        sx={{
          borderRadius: "1.5rem",
          transition: "all 200ms ease-in",
          "&:hover": {
            transform: isSmallScreen ? "translateY(0)" : "translateY(-1rem)",
          },
        }}
      >
        <CardContent sx={{ padding: "1.25rem", paddingBottom: 0 }}>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            fontWeight={700}
            fontSize={20}
            textAlign="center"
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="#4d4848"
            fontWeight={400}
            textAlign="center"
            fontSize={13}
          >
            {description}
          </Typography>

          <div
            style={{
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <img
              src={image}
              alt={title}
              height="260"
              style={{ width: "80%", margin: "auto" }}
            />
          </div>
        </CardContent>

        <CardActions
          sx={{
            padding: "0.5rem 1rem 1rem 1rem",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Link to={link}>
            <Button
              sx={{
                color: "#171515",
                borderRadius: "3.75rem",
                border: "0.125rem solid #171515",
                fontSize: "0.875rem",
                padding: "0.25rem 1.5rem",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#171515",
                },
              }}
            >
              {buttonText}
            </Button>
          </Link>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default function Landing() {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const {
    userDetails: { scopes },
  } = useAuth();

  const cardData = useMemo(() => {
    return [
      {
        image: DocumentAnalyzerImage,
        title: "AMA @ People",
        description: "Need to know about HR Policies",
        link: "/people",
        buttonText: "Explore",
        show: scopes?.includes("people"),
      },
      {
        image: CompetetiveAnalysisImage,
        title: "AMA @ Company",
        description: "Need to know about competitor analysis",
        link: "/company",
        buttonText: "Explore",
        show: scopes?.includes("company"),
      },
      {
        image: productAnalysisImage,
        title: "AMA @ Product",
        description: "Need to know about Product Catalogue",
        link: "/product",
        buttonText: "Explore",
        show: scopes?.includes("product"),
      },
    ];
  }, [scopes]);

  return (
    <>
      <UserAgreement />

      <Box
        bgcolor={"hsla(215, 15%, 97%, 0.5)"}
        height={"100svh"}
        px={2}
        pt={2}
        overflow="auto"
      >
        <Box display="flex" alignContent="flex-end" justifyContent="flex-end">
          <Profile />
        </Box>
        {!scopes || !scopes?.length ? (
          <LandingFallback />
        ) : (
          <Grid
            container
            spacing={2}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item md={9}>
              <Grid container spacing={2} justifyContent={"center"}>
                {/* Logo and Title Section */}
                <Grid item md={10} sx={{ pt: "0 !important" }}>
                  <Box
                    display={"flex"}
                    justifyContent="center"
                    alignItems={"center"}
                    mb={1}
                    gap={2}
                  >
                    <img
                      src={logo}
                      alt="HDFC Ergo Logo"
                      height={isSmallScreen ? 25 : 35}
                      style={{ objectFit: "contain" }}
                    />
                    <Typography
                      align="center"
                      fontSize={isSmallScreen ? "1.125rem" : "1.5rem"}
                      padding={isSmallScreen ? 1 : 0}
                      fontWeight={700}
                      textTransform={"uppercase"}
                    >
                      HDFC ERGO - {clientName}
                    </Typography>
                  </Box>

                  <Typography
                    variant="body1"
                    align="center"
                    fontSize={13}
                    color={"#4d4848"}
                  >
                    Ask me anything! A secure internal platform for employees to
                    explore Gen AI's potential.
                  </Typography>
                  <Typography
                    variant="body1"
                    align="center"
                    mb={3}
                    fontSize={13}
                    color={"#4d4848"}
                  >
                    Get answers through human like conversations, generate
                    quality content and more.
                  </Typography>
                </Grid>

                {/* Cards Section */}
                {cardData.map((card) => (
                  <CardItem key={card.title} {...card} />
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            // position: isSmallScreen ? "static" : "fixed",
            bottom: "0.125rem",
            pt: isSmallScreen ? 4 : 1,
          }}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Typography fontWeight={500} mr={0.6} fontSize={13}>
              Powered by
            </Typography>
            <img src={ctrlNLogo} alt="ctrl n" height={40} />
          </Box>
          {scopes?.length > 0 && <Support />}
        </Box>
      </Box>
    </>
  );
}
