import { useMemo, useState } from "react";
import CommonDialog from "./CommonDialog";
import {
  Box,
  Button,
  TextareaAutosize,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { feedbackRateButtonColors } from "../utils/constant";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import { useAuth } from "../provider/auth_provider";
import { getClient } from "../utils/http";
import { useSnackbar } from "notistack";
import { capitalize } from "../utils/common";

const StyledTextarea = styled(TextareaAutosize)({
  width: "100%",
  resize: "none",
  borderRadius: "5px",
  border: "1px solid #ccc",
  padding: "8px",
  "&:focus": {
    outline: "none",
    borderColor: "#4a4a4a",
  },
});

type FeedbackFormModalProps = {
  show: boolean;
  closeModal: () => void;
};

export default function FeedbackFormModal({
  show,
  closeModal,
}: FeedbackFormModalProps) {
  const [selectedRating, setSelectedRating] = useState<number | null>(null);
  const [feedbackText, setFeedbackText] = useState("");

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { pathname } = useLocation();
  const { userDetails } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const toolName = useMemo(() => {
    return pathname.split("/")[1];
  }, [pathname]);

  const handleButtonClick = (buttonNumber: number) => {
    setSelectedRating(buttonNumber);
  };

  const handleCloseModal = () => {
    closeModal();
  };

  const handleSubmit = async () => {
    const payload = {
      userId: userDetails?.userId,
      rating: selectedRating,
      toolName: toolName,
      feedbackText: feedbackText,
    };
    try {
      await getClient().post("/cxo-dashboard/feedback/save-feedback", payload);
      enqueueSnackbar("Feedback submitted successfully!", {
        anchorOrigin: { horizontal: "right", vertical: "top" },
        variant: "success",
        autoHideDuration: 3000,
      });
      closeModal();

      if (toolName === "people") {
        localStorage.setItem("peopleFeedbackSubmitted", "true");
      } else if (toolName === "company") {
        localStorage.setItem("companyFeedbackSubmitted", "true");
      } else if (toolName === "product") {
        localStorage.setItem("productFeedbackSubmitted", "true");
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Problem occured in submitting feedback!", {
        anchorOrigin: { horizontal: "right", vertical: "top" },
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <>
      <CommonDialog
        open={show}
        onClose={handleCloseModal}
        title={`AMA @${capitalize(toolName)} Feedback`}
      >
        <Box pt={1}>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            {Object.values(feedbackRateButtonColors).map((button) => (
              <Box
                key={button.buttonNumber}
                onClick={() => handleButtonClick(Number(button.buttonNumber))}
                sx={{
                  fontSize: isSmallScreen ? 12 : 16,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  margin: "auto",
                  width: isSmallScreen ? "1.4rem" : "1.875rem",
                  height: isSmallScreen ? "1.4rem" : "1.875rem",
                  textAlign: "center",
                  backgroundColor: button.backgroundColor,
                  boxShadow: button.boxShadow,
                  color: "#fff",
                  borderRadius: "0.25rem",
                  transform:
                    selectedRating === Number(button.buttonNumber)
                      ? "scale(1.1)"
                      : "scale(1)",
                  border:
                    selectedRating === Number(button.buttonNumber)
                      ? `1px solid ${button.borderColor}`
                      : "none",
                  transition: "transform 0.1s ease-in-out",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                {button.buttonNumber}
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              my: 1,
            }}
          >
            <Typography
              fontSize={isSmallScreen ? 10 : 12}
              color="#E42125"
              fontWeight={500}
            >
              Lowest
            </Typography>
            <Typography
              fontSize={isSmallScreen ? 10 : 12}
              color="#7DBC54"
              fontWeight={500}
            >
              Highest
            </Typography>
          </Box>

          <Box py={2}>
            <Typography
              fontSize={isSmallScreen ? 10 : 13}
              fontWeight={600}
              mb={1}
            >
              Describe what motivated your feedback?
            </Typography>

            <StyledTextarea
              minRows={5}
              placeholder="Type here..."
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
            />
          </Box>

          <Box display="flex" justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              sx={{ fontSize: isSmallScreen ? 10 : 12 }}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ fontSize: isSmallScreen ? 10 : 12 }}
              onClick={handleSubmit}
              disabled={!selectedRating || !feedbackText}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </CommonDialog>
    </>
  );
}
