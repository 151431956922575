import React, { useContext, useState } from "react";
import { decrypt } from "../utils/encryption";
import { IAuthState, IUser } from "../types/user";

const userEmptyState = {
  userId: "",
  name: "",
  username: "",
  accessToken: "",
  scopes: [],
};

const AuthContext = React.createContext<IAuthState | null>(null);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

const getUser = () => {
  const userDetails = localStorage.getItem("userDetails");
  if (userDetails) {
    return JSON.parse(decrypt(userDetails));
  } else {
    return userEmptyState;
  }
};

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [userDetails, setUserDetails] = useState<IUser>(getUser());

  const handleLogout = () => {
    setUserDetails(userEmptyState);
    localStorage.removeItem("userDetails");
    window.location.href = "/login";
  };

  return (
    <AuthContext.Provider value={{ userDetails, setUserDetails, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
}
