import React, { useState } from "react";
import { AxiosError } from "axios";
import { Form, Navigate } from "react-router-dom";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CommonInput from "../components/CommonInput";
import { encrypt } from "../utils/encryption";
import { getClient } from "../utils/http";
import { handleLogin, validateEmail } from "../utils/common";
import { useTheme } from "@mui/material/styles";
import ctrlNLogo from "../assets/teamlogo.svg";
import leftSideImage from "../assets/loginLeftSide.png";
import logo from "../assets/HDFC-Ergo-logo.png";
import Support from "../components/support";
import { useAuth } from "../provider/auth_provider";

export default function Login() {
  const [userName, setUserName] = useState<string>("");
  const [passwordValue, setPasswordValue] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { userDetails, setUserDetails } = useAuth();

  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down("md"));

  const handlePasswordVisibilityChange = () => {
    setShowPassword((prev: boolean) => !prev);
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value.trim());
    setErrorMessage("");
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordValue(e.target.value.trim());
    setErrorMessage("");
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (!userName?.length) {
        return setErrorMessage("Please enter Username or Email!");
      } else if (!passwordValue?.length) {
        return setErrorMessage("Please enter password!");
      }

      const payload = {
        email: validateEmail(userName)
          ? userName
          : userName + process.env.REACT_APP_USERNAME_EXT,
        password: passwordValue,
      };

      const response = await getClient().post("/cxo-dashboard/auth/login", {
        data: encrypt(JSON.stringify(payload)),
      });
      if (response) {
        handleLogin(response, setUserDetails);
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        setErrorMessage(
          err?.response?.data?.message || "Provide valid details"
        );
      }
    }
  };

  if (userDetails?.accessToken) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <Grid
      container
      sx={{
        height: "100vh",
        overflow: "auto",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "0.625rem",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 1,
          padding: "1rem",
          backgroundColor: "white",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "transparent",
        }}
      >
        <img
          src={logo}
          alt="logo"
          style={{
            width: isSM ? "3.75rem" : "5rem",
            height: "auto",
          }}
        />
      </Box>

      {!isSM && (
        <Grid item md={6} sm={12} xs={12}>
          <Box
            sx={{
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              overflow: "hidden",
            }}
          >
            <img
              src={leftSideImage}
              alt="leftSideImage"
              style={{
                width: "90%",
                height: "90%",
                objectFit: "cover",
              }}
            />
          </Box>
        </Grid>
      )}

      <Box
        sx={{
          background: "#f9eaeb",
          flex: 1,
          height: "100vh",
          paddingTop: isSM ? "8rem" : "0rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: isSM ? "flex-start" : "center",
            height: "100%",
          }}
        >
          <h1
            style={{
              fontSize: "1.875rem",
              fontWeight: 700,
              letterSpacing: "0.0625rem",
              textAlign: "center",
              marginTop: 0,
              marginBottom: 0,
            }}
          >
            WELCOME!
          </h1>
          <p
            style={{
              textAlign: "center",
              fontSize: "1rem",
              fontWeight: "400",
            }}
          >
            Please sign-in to your account and explore.
          </p>
          <Box
            sx={{
              padding: "1.5rem 1.5rem 1rem 1.5rem",
              width: "90%",
              maxWidth: "28.125rem",
              margin: "1rem auto 0 auto",
              borderRadius: "1.25rem",
              boxShadow: "0rem 0rem 3.75rem 0rem #7777771A",
              background: "white",
            }}
          >
            <Form onSubmit={handleFormSubmit}>
              <CommonInput
                id="username"
                label="Username (NT-ID)"
                placeholder="Enter your NT-ID"
                onChangeHandler={handleUsernameChange}
                value={userName}
              />
              <CommonInput
                id="password"
                label="Password"
                password
                passwordIconOff={<VisibilityOff sx={{ fontSize: "1.2rem" }} />}
                passwordIcon={<Visibility sx={{ fontSize: "1.2rem" }} />}
                onClickHandler={handlePasswordVisibilityChange}
                showPassword={showPassword}
                placeholder="Enter your password"
                onChangeHandler={handlePasswordChange}
                value={passwordValue}
              />
              {errorMessage && (
                <Typography variant="body2" color="error" sx={{ mb: 1 }}>
                  {errorMessage}
                </Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <a
                  style={{ color: "#2161DD", fontSize: "0.875rem" }}
                  rel="noreferrer"
                  href="https://pssm.hdfcergo.com/authorization.do"
                  target="_blank"
                >
                  Forgot Password?
                </a>
              </Box>
              <Button
                variant="contained"
                sx={{
                  background: "#252E3D",
                  width: "100%",
                  borderRadius: "0.3125rem",
                  fontSize: "0.8rem ",
                  fontWeight: "600",
                  padding: "0.5rem 1rem",
                  marginTop: "1.5rem",
                }}
                type="submit"
              >
                Sign In
              </Button>
            </Form>
            <p
              style={{
                textAlign: "center",
                marginTop: "1rem",
                fontSize: "0.875rem",
              }}
            >
              <span style={{ color: "#ADAEB0" }}>
                By signing in, you agree to the
              </span>
              &nbsp;
              <a
                style={{ color: "#2161DD" }}
                rel="noreferrer"
                href="https://www.hdfcergo.com/terms-conditions/terms"
                target="_blank"
              >
                Terms and Conditions.
              </a>
            </p>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                mt: 1.6,
              }}
            >
              <Box display={"flex"} alignItems={"center"} mb={0.5}>
                <Typography fontWeight={500} mr={0.6} fontSize={13}>
                  Powered by
                </Typography>
                <img src={ctrlNLogo} alt="ctrl n" height={40} />
              </Box>
              <Support />
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}
