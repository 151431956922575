import { Box, useMediaQuery, useTheme } from "@mui/material";
import Linechart from "./graphs/Linechart";
import Barchart from "./graphs/Barchart";
import Datatable from "./graphs/Datatable";
import Comparetable from "./graphs/Comparetable";
import Piechart from "./graphs/Piechart";

const chartComponents: Record<string, React.ComponentType<{ data: any }>> = {
  "bar chart": Barchart,
  "pie chart": Piechart,
  "line chart": Linechart,
  "data table": Datatable,
  "compare table": Comparetable,
};

export default function Graphs({ type, data }: { type: string; data: any }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const ChartComponent = chartComponents[type];

  if (
    ![
      "bar chart",
      "pie chart",
      "line chart",
      "data table",
      "compare table",
    ].includes(type)
  ) {
    return null;
  }

  return (
    <Box
      mt={isSmallScreen ? 2 : 4}
      maxWidth={"70vw"}
      overflow={"auto"}
      display="flex"
      padding={1}
      flexDirection="column"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      margin="auto"
    >
      {ChartComponent && <ChartComponent data={data} />}
    </Box>
  );
}
