import { Outlet, Navigate, useLocation } from "react-router-dom";

import { useAuth } from "../provider/auth_provider";

export default function PrivateRoute() {
  const { userDetails } = useAuth();

  const { pathname } = useLocation();

  const isProtectedRoute = ["/people", "/product", "/company"].includes(
    pathname
  );

  const hasAccess =
    userDetails.scopes && userDetails.scopes?.includes(pathname.split("/")[1]);

  if (!userDetails.accessToken) {
    return <Navigate to="/login" />;
  }

  if (isProtectedRoute && !hasAccess) {
    return <Navigate to="/dashboard" />;
  }

  return <Outlet />;
}
