import { useMemo } from "react";
import { useMediaQuery } from "@mui/material";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";
import { COLORS } from "../../utils/constant";
import theme from "../../theme";
import { capitalize, roundOff } from "../../utils/common";

export default function Piechart({ data }: any) {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const formattedData = useMemo(() => {
    return data.map((item: any, index: any) => {
      let valueKey = Object.keys(item).find(
        (key) =>
          typeof item[key] === "number" && !["month", "year"].includes(key)
      );
      let labelKey = Object.keys(item).find(
        (key) => typeof item[key] === "string"
      );
      return {
        id: index,
        value: roundOff(item[valueKey!]),
        label: capitalize(item[labelKey!]),
      };
    });
  }, [data]);

  if (Object.keys(data[0]).length > 3) {
    return null;
  }

  return (
    <ResponsiveContainer minHeight={350} maxHeight={700}>
      <PieChart>
        <Pie
          data={formattedData}
          dataKey="value"
          nameKey="label"
          cx="50%"
          cy="50%"
          outerRadius={isSmallScreen ? 90 : 110}
          fill="#8884d8"
          style={{ paddingBottom: "1.25rem" }}
        >
          {formattedData.map((item: any, index: number) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        {formattedData.length < 8 && (
          <Legend
            layout={isSmallScreen ? "horizontal" : "vertical"}
            align={isSmallScreen ? "center" : "right"}
            verticalAlign={isSmallScreen ? "bottom" : "middle"}
          />
        )}
      </PieChart>
    </ResponsiveContainer>
  );
}
